import * as React from 'react'
import { Trans, t } from '@lingui/macro'

import { ItemType, TopLevelReviewFragment } from '../../api/types'

import { useLocalisation } from '../../lib/i18n'

import { Button } from '@discogs/amped/button'
import { Spinner } from '../../lib/components/spinner'
import { Link } from '../../lib/components/link'
import { TextArea } from '../../lib/components/textarea'
import { ExclamationCircle } from '../../lib/components/icon'
import { useInteraction } from '../../lib/components/analytics'

import { mutations, useEditReviewMutation } from './mutations'
import { Preview } from './preview'

import css from './editor.css'

type EditorProps = {
    keyReleaseId?: number
    discogsId: number
    reviewId?: number
    parent?: TopLevelReviewFragment
    isEdit?: boolean
    initialValue?: string
    onCancel?: () => void
    itemType: ItemType
}

const requiredWordCount = 10

export function Editor(props: EditorProps): React.ReactElement {
    const { i18n } = useLocalisation()
    const { parent, onCancel, isEdit, initialValue = '', reviewId, discogsId, keyReleaseId, itemType } = props
    const [value, setValue] = React.useState(initialValue)

    const { useCreate } = mutations[itemType]
    const [createReview, create] = useCreate({
        discogsId,
        keyReleaseId,
        content: value,
        parent,
        itemType,
    })

    const [editReview, edit] = useEditReviewMutation({
        reviewId,
        content: value,
    })

    const track = useInteraction()

    const loading = create.loading || edit.loading
    const error = create.error ?? edit.error

    function reset(): void {
        setValue('')
    }

    const wordCount = value
        .split(/\s+/)
        .map((s: string): string => s.trim())
        .filter((s: string): boolean => s !== '').length

    const missingWords = requiredWordCount - wordCount
    const disable = loading || value === '' || missingWords > 0

    function handleChange(evt: React.ChangeEvent<HTMLTextAreaElement>): void {
        setValue(evt.target.value)
    }

    async function handlePost(evt: React.FormEvent): Promise<void> {
        evt.preventDefault()
        if (disable) {
            return
        }

        track('Add Review')

        if (isEdit) {
            await editReview()
        } else {
            await createReview()
        }

        reset()
        onCancel?.()
    }

    function handleQuote(): void {
        setValue(function (value: string): string {
            if (!parent) {
                return value
            }

            return `[quote=${parent.reviewer.username}] ${parent.content.markup} [/quote]\n${value}`
        })
    }

    return (
        <form className={css.editor} onSubmit={handlePost}>
            <TextArea
                name='review'
                className={css.input}
                placeholder={parent ? t(i18n)`Enter your reply` : t(i18n)`Enter your comment`}
                onChange={handleChange}
                value={value}
                disabled={loading}
            />
            <Preview value={value} />
            {value !== '' && missingWords > 0 && (
                <div className={css.count}>
                    <ExclamationCircle />
                    <Trans>At least 10 words must be entered. Please enter at least {missingWords} more.</Trans>
                </div>
            )}
            <div className={css.toolbar}>
                <div className={css.action}>
                    <Button variant='primary' disabled={disable} className={css.submit} type='submit'>
                        {isEdit ? <Trans>Save Changes</Trans> : <Trans>Submit</Trans>}
                    </Button>
                    {parent && !isEdit && (
                        <Button disabled={loading} className={css.submit} onClick={handleQuote}>
                            <Trans>Quote Previous</Trans>
                        </Button>
                    )}
                    {onCancel && (
                        <Button variant='ghost' onClick={onCancel} color='red'>
                            <Trans>Cancel</Trans>
                        </Button>
                    )}
                    {loading && <Spinner />}
                    {error && (
                        <span className={css.error}>
                            <Trans>Something went wrong adding your review</Trans>
                        </span>
                    )}
                </div>
                <Link href='/help/doc/reviews-and-discussion'>
                    <Trans>View Help</Trans>
                </Link>
            </div>
        </form>
    )
}
