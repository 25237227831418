import * as React from 'react'
import { Trans } from '@lingui/macro'

import { Link } from '../../lib/components/link'

import { ReleaseReviewItemFragment, ReleaseVersionFragment } from '../../api/types'

import { intersperse } from '../../lib/intersperse'

type Props = {
    reviewItem: {
        discogsId: number
        title?: string
        siteUrl: string
        labels?: {
            catalogNumber?: string
        }[]
        formats?: {
            name: string
            quantity?: string
            description?: string[] | undefined
            text?: string
        }[]
    }
}

type Format = NonNullable<ReleaseVersionFragment['formats']>[0]
type FormatProps = {
    formats?: Format[]
}
type LabelRelationship = NonNullable<ReleaseReviewItemFragment['labels']>[number]
type LabelLinkProps = {
    labels?: LabelRelationship[]
    noCatNos?: boolean
}

function ReleaseLabelLink(props: LabelLinkProps): React.ReactElement | null {
    const { labels, noCatNos = false } = props
    if (!labels || labels.length === 0) {
        return null
    }
    return (
        <>
            {intersperse(
                ', ',
                labels.slice(0, 1).map(function (rel: LabelRelationship): React.ReactElement | null {
                    const { catalogNumber } = rel
                    const key = `${catalogNumber ?? '_'}`

                    return <React.Fragment key={key}>{!noCatNos && catalogNumber && `${catalogNumber}`}</React.Fragment>
                }),
            )}
        </>
    )
}

function Formats(props: FormatProps): React.ReactElement | null {
    const { formats } = props

    if (!formats || formats.length === 0) {
        return null
    }

    return (
        <>
            (
            {intersperse(
                ', ',
                formats.map(function (format: Format): React.ReactElement {
                    const { name, quantity, description, text } = format
                    return (
                        <React.Fragment key={name}>
                            {quantity && quantity !== '1' && <>{quantity}&times;</>}
                            {name !== 'Vinyl' && name}
                            {name !== 'Vinyl' && description && ', '}
                            {description?.join(', ')}
                            {text && (
                                <>
                                    , <span>{text}</span>
                                </>
                            )}
                        </React.Fragment>
                    )
                }),
            )}
            )
        </>
    )
}

export function Referencing(props: Props): React.ReactElement {
    const { reviewItem } = props

    return (
        <div style={{ fontStyle: 'italic', fontSize: '0.85em' }}>
            <p>
                <Trans>referencing </Trans>{' '}
                <Link href={reviewItem.siteUrl ? `https://www.discogs.com${reviewItem.siteUrl}` : ''}>
                    {reviewItem.title} <Formats formats={reviewItem.formats} />{' '}
                    <ReleaseLabelLink labels={reviewItem.labels} />
                </Link>
            </p>
        </div>
    )
}
