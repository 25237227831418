import { Maybe } from '../api/types'

type Edge<T> = { node?: Maybe<T> }

export function extractNodes<T>(edges: Maybe<Edge<T>[]>): T[] {
    if (!edges) {
        return []
    }

    const t = []
    for (const edge of edges) {
        if (typeof edge.node !== 'undefined') {
            t.push(edge.node)
        }
    }

    return t
}

type Connection<T> = {
    totalCount: number
    edges: Edge<T>[]
}

export function nodes<T>(items: T[], totalCount: number = items.length): Connection<T> {
    return {
        totalCount,
        edges: items.map(edge),
    }
}

function edge<T>(node: T): Edge<T> {
    return { node }
}

export function empty<T>(): Connection<T> {
    return nodes([], 0)
}
