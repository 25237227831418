import { ApolloCache, FetchResult } from '@apollo/client'
import { t } from '@lingui/macro'

import { useLogin } from '../lib/login'
import { useErrorHandler } from '../lib/components/errors'
import {
    useAddReleasesToWantlistMutation as useAddReleasesToWantlistMutation_,
    AddReleasesToWantlistMutation,
    WantlistItem,
    WantlistItemFragmentDoc,
    ReleaseMasterFragmentDoc,
    ReleaseMasterFragment,
} from '../api/types'

type Params = {
    discogsIds: number[]
}

type Mutation = {
    called: boolean
    loading: boolean
    perform: (params: Params) => Promise<void>
}

export function useAddReleasesToWantlistMutation(): Mutation {
    const { user } = useLogin()

    const [add, info] = useAddReleasesToWantlistMutation_()
    const onError = useErrorHandler(t`Could not add release to wantlist`)

    async function perform(params: Params): Promise<void> {
        const { discogsIds } = params
        if (!user) {
            return
        }

        await add({
            variables: {
                input: {
                    releaseDiscogsIds: discogsIds,
                },
            },
            optimisticResponse: {
                addReleasesToWantlist: {
                    // @ts-expect-error: typenames are not generated
                    __typename: 'AddReleasesToWantlistPayload',
                    wantlistItems: discogsIds.map((discogsId) => ({
                        __typename: 'WantlistItem',
                        discogsId: Math.round(Math.random() * 100000),
                        addedAt: new Date().toISOString(),
                        areNotesPublic: false,
                        release: {
                            discogsId,
                        },
                        notes: {
                            markup: '',
                            html: '',
                        },
                    })),
                },
            },
            update(
                cache: ApolloCache<AddReleasesToWantlistMutation>,
                result: FetchResult<AddReleasesToWantlistMutation>,
            ): void {
                if (!result.data) {
                    return
                }

                for (const item of result.data.addReleasesToWantlist.wantlistItems ?? []) {
                    if (!item) {
                        continue
                    }

                    const id = cache.identify({ __typename: 'Release', discogsId: item.release.discogsId })
                    const res = cache.readFragment({
                        id,
                        fragment: ReleaseMasterFragmentDoc,
                    }) as ReleaseMasterFragment | undefined

                    cache.modify({
                        id: cache.identify({ __typename: 'Release', discogsId: item.release.discogsId }),
                        fields: {
                            inWantlist(): WantlistItem | undefined {
                                const ref = cache.writeFragment({
                                    fragment: WantlistItemFragmentDoc,
                                    data: item,
                                })
                                // @ts-expect-error
                                return ref
                            },
                        },
                    })

                    if (res?.masterRelease) {
                        cache.modify({
                            id: cache.identify({ __typename: 'MasterRelease', discogsId: res.masterRelease.discogsId }),
                            fields: {
                                inUserWantlistCount(existing: number | undefined): number {
                                    return (existing ?? 0) + 1
                                },
                            },
                        })
                    }
                }
            },
        }).catch(onError)
    }

    return {
        ...info,
        perform,
    }
}
