import * as React from 'react'
import { useRouteMatch, useLocation } from 'react-router'

import { Link } from '../../lib/components/link'
import css from './link.css'

type Props = {
    imageId: string
    children: React.ReactNode
    className?: string
}

export function GalleryLink(props: Props): React.ReactElement {
    const { imageId, ...rest } = props

    const location = useLocation()
    const match = useRouteMatch()

    const href = {
        ...location,
        pathname: `${match.url}/image/${imageId}`,
    }

    return <Link internal replace href={href} {...rest} className={css.link} />
}
