export function year(date: string): number {
    const parts = date.split('-')
    return parseInt(parts[0], 10)
}

export function decade(date: string): number {
    const y = year(date)
    return Math.floor(y / 10) * 10
}

export function dateFormat(date: string): string {
    if (!date) {
        return ''
    }
    const dateObj = new Date(date)
    const formattedDate = dateObj.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })

    return formattedDate ? formattedDate : ''
}

export function shortDateFormat(date: string): string {
    if (!date) {
        return ''
    }
    const dateObj = new Date(date)
    const formattedDate = dateObj.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })

    return formattedDate ? formattedDate : ''
}
