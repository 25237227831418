import { ApolloCache, FetchResult } from '@apollo/client'
import { t } from '@lingui/macro'

import {
    ItemType,
    useAddItemToNewListMutation as useAddItemToNewListMutation_,
    AddItemToNewListMutation,
    ListInfoFragmentDoc,
    ReleaseListsFragment,
    User,
} from '../api/types'
import { useErrorHandler } from '../lib/components/errors'
import { useInteraction } from '../lib/components/analytics'
import { Modifier } from '@apollo/client/cache'

type Params = {
    discogsId: number
    title: string
    description: string
    comment: string
    itemType: ItemType
}

type Mutation = {
    loading: boolean
    error?: Error
    perform: (params: Params) => Promise<void>
}

type ListsConnection = ReleaseListsFragment['lists']

function toTypeName(itemType: ItemType): 'Release' | 'MasterRelease' {
    if (itemType === ItemType.MasterRelease) {
        return 'MasterRelease'
    }
    return 'Release'
}

export function useAddItemToNewListMutation(): Mutation {
    const [add, info] = useAddItemToNewListMutation_()
    const onError = useErrorHandler(t`Could not add release to list`)
    const track = useInteraction()

    async function perform(params: Params): Promise<void> {
        const { discogsId, title, description, comment, itemType } = params

        track('Add to List')

        await add({
            variables: {
                input: {
                    itemDiscogsId: discogsId,
                    itemType,
                    title,
                    description,
                    comment,
                },
            },
            update(cache: ApolloCache<AddItemToNewListMutation>, result: FetchResult<AddItemToNewListMutation>): void {
                cache.modify({
                    id: cache.identify({ __typename: toTypeName(itemType), discogsId }),
                    fields: {
                        lists: ((existing: ListsConnection | undefined): ListsConnection | undefined => {
                            if (!existing || !result.data?.createList?.curatedList) {
                                return existing
                            }

                            const ref = cache.writeFragment({
                                data: result.data.createList.curatedList,
                                fragment: ListInfoFragmentDoc,
                            })

                            if (!ref) {
                                return existing
                            }

                            const edge = {
                                node: ref,
                            }

                            return {
                                ...existing,
                                totalCount: existing.totalCount + 1,
                                // @ts-expect-error
                                edges: [edge, ...existing.edges],
                            }
                            // Apollo Client (<= 3.x) has strict Modifier type requirements that
                            // can be difficult to satisfy when working with complex nested types.
                            // This type assertion works around those type mismatches.
                            // TODO: Consider refactoring to use a helper function for better type safety.
                            // https://discogsinc.atlassian.net/browse/RALM-2748
                        }) as unknown as Modifier<null>,
                    },
                })

                cache.modify({
                    id: cache.identify({ __typename: 'Query' }),
                    fields: {
                        viewer: ((existing: User | null): User | null => {
                            if (!existing || !result.data?.createList) {
                                return existing
                            }

                            const ref = cache.writeFragment({
                                fragment: ListInfoFragmentDoc,
                                data: result.data.createList.curatedList,
                            })

                            const keys = [
                                `lists:{}`,
                                `lists:{"catalogItem":{"itemDiscogsId":${discogsId},"itemType":"${itemType}"}}`,
                                `lists:{"catalogItem":{"itemType":"${itemType}","itemDiscogsId":${discogsId}}}`,
                            ]

                            const res = { ...existing }
                            for (const key of keys) {
                                // @ts-expect-error: ts does not know about key
                                const value = existing[key] as User['lists'] | undefined
                                if (!value) {
                                    continue
                                }

                                // @ts-expect-error: ts does not know about refs
                                if (value.edges.find((edge) => edge.node?.__ref === ref?.__ref)) {
                                    continue
                                }

                                // @ts-expect-error: ts does not know about key
                                res[key] = {
                                    ...value,
                                    totalCount: value.totalCount + 1,
                                    edges: [...value.edges, { node: ref }],
                                }
                            }

                            return res
                            // Apollo Client (<= 3.x) has strict Modifier type requirements that
                            // can be difficult to satisfy when working with complex nested types.
                            // This type assertion works around those type mismatches.
                            // TODO: Consider refactoring to use a helper function for better type safety.
                            // https://discogsinc.atlassian.net/browse/RALM-2748
                        }) as unknown as Modifier<null>,
                    },
                })
            },
        }).catch(onError)
    }

    return {
        ...info,
        perform,
    }
}
