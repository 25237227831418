/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { ApolloCache, FetchResult } from '@apollo/client'
import { t } from '@lingui/macro'

import { useLogin } from '../lib/login'
import { useErrorHandler } from '../lib/components/errors'
import {
    useAddReleaseToCollectionMutation as useAddReleaseToCollectionMutation_,
    AddReleaseToCollectionMutation,
    CollectionItemConnection,
    ReleaseCollectionItemFragmentDoc,
    ReleaseMasterFragmentDoc,
    ReleaseMasterFragment,
} from '../api/types'
import { Modifier } from '@apollo/client/cache'

type Params = {
    discogsId: number
}

type Mutation = {
    loading: boolean
    called: boolean
    perform: (params: Params) => Promise<void>
}

export function useAddReleaseToCollectionMutation(): Mutation {
    const { user } = useLogin()
    const onError = useErrorHandler(t`Could not add release to collection`)

    const [add, info] = useAddReleaseToCollectionMutation_()

    async function perform(params: Params): Promise<void> {
        const { discogsId } = params
        if (!user) {
            return
        }

        await add({
            variables: {
                input: {
                    discogsReleaseId: discogsId,
                },
            },
            optimisticResponse: {
                addCollectionItem: {
                    // @ts-expect-error: typenames are not generated
                    __typename: 'AddCollectionAPIItemPayload',
                    collectionItemId: Math.round(Math.random() * 100000),
                },
            },
            update(
                cache: ApolloCache<AddReleaseToCollectionMutation>,
                result: FetchResult<AddReleaseToCollectionMutation>,
            ): void {
                const id = cache.identify({ __typename: 'Release', discogsId })
                const res = cache.readFragment({
                    id,
                    fragment: ReleaseMasterFragmentDoc,
                }) as ReleaseMasterFragment | undefined

                cache.modify({
                    id,
                    fields: {
                        collectionItems: ((
                            existing: CollectionItemConnection | undefined,
                        ): CollectionItemConnection | undefined => {
                            if (!existing) {
                                return existing
                            }

                            if (!result.data) {
                                return existing
                            }

                            const ref = cache.writeFragment({
                                fragment: ReleaseCollectionItemFragmentDoc,
                                fragmentName: 'ReleaseCollectionItem',
                                data: {
                                    __typename: 'CollectionItem',
                                    discogsId: result.data.addCollectionItem.collectionItemId,
                                    addedAt: new Date().toISOString(),
                                    notes: [],
                                    folder: { name: 'Uncategorized' },
                                },
                            })

                            const edge = {
                                cursor: btoa(`${result.data.addCollectionItem.collectionItemId}`),
                                node: ref,
                            }

                            return {
                                ...existing,
                                totalCount: existing.totalCount + 1,
                                // @ts-expect-error
                                edges: [...(existing.edges ?? []), edge],
                            }
                            // Apollo Client (<= 3.x) has strict Modifier type requirements that
                            // can be difficult to satisfy when working with complex nested types.
                            // This type assertion works around those type mismatches.
                            // TODO: Consider refactoring to use a helper function for better type safety.
                            // https://discogsinc.atlassian.net/browse/RALM-2748
                        }) as unknown as Modifier<null>,
                    },
                })

                if (res?.masterRelease) {
                    cache.modify({
                        id: cache.identify({ __typename: 'MasterRelease', discogsId: res.masterRelease.discogsId }),
                        fields: {
                            inUserCollectionCount(existing: number | undefined): number {
                                return (existing ?? 0) + 1
                            },
                        },
                    })
                }
            },
        }).catch(onError)
    }

    return {
        perform,
        ...info,
    }
}
