import { ApolloCache, FetchResult } from '@apollo/client'
import { t } from '@lingui/macro'

import {
    ItemType,
    useRemoveItemFromListMutation as useRemoveItemFromListMutation_,
    RemoveItemFromListMutation,
    ReleaseListsFragment,
    User,
} from '../api/types'

import { useErrorHandler } from '../lib/components/errors'
import { Modifier } from '@apollo/client/cache'

type Params = {
    discogsId: number
    itemType: ItemType
    listId: number
}

type Mutation = {
    loading: boolean
    error?: Error
    perform: (params: Params) => Promise<void>
}

type ListsConnection = ReleaseListsFragment['lists']

function toTypeName(itemType: ItemType): 'Release' | 'MasterRelease' | 'Artist' | 'Label' {
    if (itemType === ItemType.MasterRelease) {
        return 'MasterRelease'
    }
    if (itemType === ItemType.Artist) {
        return 'Artist'
    }
    if (itemType === ItemType.Label) {
        return 'Label'
    }
    return 'Release'
}

export function useRemoveItemFromListMutation(): Mutation {
    const [remove, info] = useRemoveItemFromListMutation_()
    const onError = useErrorHandler(t`Could not remove the list item`)

    async function perform(params: Params): Promise<void> {
        const { listId, discogsId, itemType } = params

        await remove({
            variables: {
                input: {
                    itemType,
                    itemDiscogsId: discogsId,
                    listDiscogsId: listId,
                },
            },
            optimisticResponse: {
                removeFromList: {
                    // @ts-expect-error: typescript does not know about __typename
                    __typename: 'RemoveItemPayload',
                    success: true,
                },
            },
            update(
                cache: ApolloCache<RemoveItemFromListMutation>,
                result: FetchResult<RemoveItemFromListMutation>,
            ): void {
                cache.modify({
                    id: cache.identify({ __typename: toTypeName(itemType), discogsId }),
                    fields: {
                        lists: ((existing: ListsConnection | undefined): ListsConnection | undefined => {
                            if (!existing || !result.data?.removeFromList?.success) {
                                return existing
                            }

                            const ref = cache.identify({ __typename: 'CuratedList', discogsId: listId })

                            return {
                                ...existing,
                                totalCount: Math.max(0, existing.totalCount - 1),
                                // @ts-expect-error: TS does not know about cache refs
                                edges: existing.edges.filter((edge: ListsEdge): boolean => edge.node.__ref !== ref),
                            }
                            // Apollo Client (<= 3.x) has strict Modifier type requirements that
                            // can be difficult to satisfy when working with complex nested types.
                            // This type assertion works around those type mismatches.
                            // TODO: Consider refactoring to use a helper function for better type safety.
                            // https://discogsinc.atlassian.net/browse/RALM-2748
                        }) as unknown as Modifier<null>,
                    },
                })

                cache.modify({
                    id: cache.identify({ __typename: 'Query' }),
                    fields: {
                        viewer: ((existing: User | null): User | null => {
                            if (!existing || !result.data?.removeFromList?.success) {
                                return existing
                            }

                            const ref = cache.identify({ __typename: 'CuratedList', discogsId: listId })

                            const keys = [
                                `lists:{"catalogItem":{"itemDiscogsId":${discogsId},"itemType":"${itemType}"}}`,
                                `lists:{"catalogItem":{"itemType":"${itemType}","itemDiscogsId":${discogsId}}}`,
                                `lists:{}`,
                            ]

                            const res = { ...existing }
                            for (const key of keys) {
                                // @ts-expect-error: ts does not know about key
                                const value = existing[key] as User['lists'] | undefined
                                if (!value) {
                                    continue
                                }

                                // @ts-expect-error: ts does not know about refs
                                if (value.edges.find((edge) => edge.node?.__ref === ref?.__ref)) {
                                    continue
                                }

                                // @ts-expect-error: ts does not know about key
                                res[key] = {
                                    ...value,
                                    totalCount: Math.max(0, value.totalCount - 1),
                                    // @ts-expect-error: ts does not know about refs
                                    edges: value.edges.filter((edge) => edge.node?.__ref !== ref),
                                }
                            }

                            return res
                            // Apollo Client (<= 3.x) has strict Modifier type requirements that
                            // can be difficult to satisfy when working with complex nested types.
                            // This type assertion works around those type mismatches.
                            // TODO: Consider refactoring to use a helper function for better type safety.
                            // https://discogsinc.atlassian.net/browse/RALM-2748
                        }) as unknown as Modifier<null>,
                    },
                })
            },
        }).catch(onError)
    }

    return {
        ...info,
        perform,
    }
}
