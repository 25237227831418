import { t } from '@lingui/macro'
import { useLocalisation } from './i18n'

const max = 2 ** 31

export function useDiscogsId(id: string | number): number {
    const { i18n } = useLocalisation()
    const parsed = parse(id)

    if (parsed >= max) {
        /* eslint-disable-next-line no-throw-literal */
        throw {
            status: 400,
            message: t(i18n)`The value "${id}" is not a valid Discogs identifier.`,
        }
    }

    return parsed
}

function parse(id: string | number): number {
    if (typeof id === 'number') {
        return id
    }

    return parseInt(id, 10)
}
