import * as React from 'react'
import { ErrorBoundary } from '../error-boundary'
import { Trans } from '@lingui/macro'

type ErrorSectionProps = {
    children: React.ReactNode | React.ReactNode[]
}

export function ErrorSection(props: ErrorSectionProps): React.ReactElement {
    return <ErrorBoundary fallback={Fallback} {...props} />
}

function Fallback(): React.ReactElement {
    return <Trans>Something went wrong in this section of the page.</Trans>
}
