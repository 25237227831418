import * as React from 'react'
import { Link } from 'react-head'
import { useLocation } from 'react-router'

import { useLocalisation, Language } from '.'

type Props = {
    languages: readonly Language[]
    host: string
}

export function AlternateLinks(props: Props): React.ReactElement {
    const { languages, host } = props
    const { localiseURL } = useLocalisation()
    const location = useLocation()

    const links = languages.map(
        (lang: Language): React.ReactElement => (
            <Link
                key={lang}
                rel='alternate'
                hrefLang={lang.replace('_', '-')}
                href={`${host}${localiseURL(location.pathname, lang)}`}
            />
        ),
    )

    return <>{links}</>
}
