import { ApolloCache, FetchResult } from '@apollo/client'
import { t } from '@lingui/macro'

import { useLogin } from '../lib/login'
import { useErrorHandler } from '../lib/components/errors'
import {
    useRemoveReleasesFromWantlistMutation as useRemoveReleasesFromWantlistMutation_,
    RemoveReleasesFromWantlistMutation,
    WantlistItem,
    ReleaseMasterFragmentDoc,
    ReleaseMasterFragment,
} from '../api/types'
import { Modifier } from '@apollo/client/cache'

type Params = {
    discogsIds: number[]
}

type Mutation = {
    called: boolean
    loading: boolean
    perform: (params: Params) => Promise<void>
}

export function useRemoveReleasesFromWantlistMutation(): Mutation {
    const onError = useErrorHandler(t`Could not remove release from wantlist`)
    const [remove, info] = useRemoveReleasesFromWantlistMutation_()
    const { user } = useLogin()

    async function perform(params: Params): Promise<void> {
        const { discogsIds } = params
        if (!user) {
            return
        }

        await remove({
            variables: {
                input: {
                    releaseDiscogsIds: discogsIds,
                },
            },
            optimisticResponse: {
                removeReleasesFromWantlist: {
                    // @ts-expect-error: typenames are not generated
                    __typename: 'RemoveItemPayload',
                    success: true,
                },
            },
            update(
                cache: ApolloCache<RemoveReleasesFromWantlistMutation>,
                result: FetchResult<RemoveReleasesFromWantlistMutation>,
            ): void {
                for (const discogsId of discogsIds) {
                    const id = cache.identify({ __typename: 'Release', discogsId })
                    const res = cache.readFragment({
                        id,
                        fragment: ReleaseMasterFragmentDoc,
                    }) as ReleaseMasterFragment | undefined
                    cache.modify({
                        id: cache.identify({ __typename: 'Release', discogsId }),
                        fields: {
                            inWantlist: ((existing: WantlistItem): WantlistItem | null => {
                                if (!result.data) {
                                    return existing
                                }
                                return null
                                // Apollo Client (<= 3.x) has strict Modifier type requirements that
                                // can be difficult to satisfy when working with complex nested types.
                                // This type assertion works around those type mismatches.
                                // TODO: Consider refactoring to use a helper function for better type safety.
                                // https://discogsinc.atlassian.net/browse/RALM-2748
                            }) as unknown as Modifier<null>,
                        },
                    })

                    if (res?.masterRelease) {
                        cache.modify({
                            id: cache.identify({ __typename: 'MasterRelease', discogsId: res.masterRelease.discogsId }),
                            fields: {
                                inUserWantlistCount(existing: number | undefined): number {
                                    return Math.max(0, (existing ?? 0) - 1)
                                },
                            },
                        })
                    }
                }
            },
        }).catch(onError)
    }

    return {
        ...info,
        perform,
    }
}
