import * as React from 'react'
import classnames from 'classnames'

import css from './styles.css'

type Props = React.InputHTMLAttributes<HTMLInputElement> & { className?: string }

export function Input(props: Props): React.ReactElement {
    return <input {...props} className={classnames(css.input, props.className)} />
}
