import { addState } from '../../add-state'
import { RatingsUI } from './ui'

export type Props = {
    value: null | number
    onChange(value: null | number): void
}

export type State = {
    hovering: number | null
}

export type Action = { type: 'hover'; rating: number | null }

const initial = {
    hovering: null,
}

export function reducer(props: Props, state: State, action: Action): State {
    switch (action.type) {
        // ts linter thinks this is a conditional?
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        case 'hover':
            return { ...state, hovering: action.rating }
        default:
            return state
    }
}

export const Ratings = addState(RatingsUI, reducer, initial)
export { RatingsValue } from './ui'
