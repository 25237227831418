import * as React from 'react'

type UseIdle = (fn: () => void, changes?: unknown[]) => void

export const useIdle = build()

export function build(): UseIdle {
    if (!process.browser) {
        // Do nothing on the server
        return function (): void {
            // noop
        }
    }

    /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
    if (!window.requestIdleCallback || !window.cancelIdleCallback) {
        // Fall back to using useEffect on first render
        // if requestIdleCallback does not exist
        return function useIdleCallback(callback: () => void, changes: unknown[] = []): void {
            React.useEffect(callback, changes)
        }
    }

    return function useIdleCallback(callback: () => void, changes: unknown[] = []): void {
        React.useEffect(function (): () => void {
            const handle = window.requestIdleCallback(callback)
            return (): void => window.cancelIdleCallback(handle)
        }, changes)
    }
}
