import { ApolloCache, FetchResult, Reference } from '@apollo/client'
import { t } from '@lingui/macro'

import {
    ItemType,
    ReleaseReviewsFragmentDoc,
    ArtistReviewsFragmentDoc,
    LabelReviewsFragmentDoc,
    CreateReleaseReviewMutation,
    useCreateReleaseReviewMutation as useCreateReleaseReviewMutation_,
    CreateArtistReviewMutation,
    useCreateArtistReviewMutation as useCreateArtistReviewMutation_,
    CreateLabelReviewMutation,
    useCreateLabelReviewMutation as useCreateLabelReviewMutation_,
    ToggleFoundReviewHelpfulMutation,
    useToggleFoundReviewHelpfulMutation as useToggleFoundReviewHelpfulMutation_,
    useEditReviewMutation as useEditReviewMutation_,
    RemoveReviewMutation,
    useRemoveReviewMutation as useRemoveReviewMutation_,
    TopLevelReviewFragment,
    useArtistMoreReviewsLazyQuery,
    useMasterMoreReviewsLazyQuery,
    useReleaseMoreReviewsLazyQuery,
    useLabelMoreReviewsLazyQuery,
} from '../../api/types'

import { useErrorHandler } from '../../lib/components/errors'
import { Modifier } from '@apollo/client/cache'

export const mutations = {
    MASTER_RELEASE: {
        useMoreLazyQuery: useMasterMoreReviewsLazyQuery,
        useCreate: useCreateReleaseReviewMutation,
        typeName: 'MasterRelease',
    },
    RELEASE: {
        useMoreLazyQuery: useReleaseMoreReviewsLazyQuery,
        useCreate: useCreateReleaseReviewMutation,
        typeName: 'Release',
    },
    ARTIST: {
        useMoreLazyQuery: useArtistMoreReviewsLazyQuery,
        useCreate: useCreateArtistReviewMutation,
        typeName: 'Artist',
    },
    LABEL: {
        useMoreLazyQuery: useLabelMoreReviewsLazyQuery,
        useCreate: useCreateLabelReviewMutation,
        typeName: 'Label',
    },
} as const

type ReviewConnection = {
    totalCount: number
    edges: {
        cursor: string
        node: Reference
    }[]
}

type Info = {
    loading: boolean
    error?: Error
}

type Mutation = [() => Promise<void>, Info]

type RemoveOptions = {
    discogsReviewId: number
    discogsId?: number
    parent?: TopLevelReviewFragment
    itemType: ItemType
}

export function useRemoveReviewMutation(opts: RemoveOptions): Mutation {
    const { discogsReviewId, parent, discogsId, itemType } = opts
    const { typeName } = mutations[itemType]

    const [perform, info] = useRemoveReviewMutation_()
    const onError = useErrorHandler(t`Could not remove review`)

    async function removeR(): Promise<void> {
        await perform({
            variables: {
                input: {
                    discogsReviewId,
                },
            },
            update(cache: ApolloCache<RemoveReviewMutation>, result: FetchResult<RemoveReviewMutation>): void {
                if (parent) {
                    cache.modify({
                        id: cache.identify({ __typename: 'Review', discogsId: parent.discogsId }),
                        fields: {
                            replies: ((existing: Reference[]): Reference[] => {
                                const id = cache.identify({ __typename: 'Review', discogsId: discogsReviewId })
                                return existing.filter((r: Reference): boolean => r.__ref !== id)
                                // Apollo Client (<= 3.x) has strict Modifier type requirements that
                                // can be difficult to satisfy when working with complex nested types.
                                // This type assertion works around those type mismatches.
                                // TODO: Consider refactoring to use a helper function for better type safety.
                                // https://discogsinc.atlassian.net/browse/RALM-2748
                            }) as unknown as Modifier<null>,
                        },
                    })
                } else {
                    cache.modify({
                        id: cache.identify({ __typename: typeName, discogsId }),
                        fields: {
                            reviews: ((existing: ReviewConnection | undefined): ReviewConnection | undefined => {
                                if (!existing) {
                                    return existing
                                }
                                const id = cache.identify({ __typename: 'Review', discogsId: discogsReviewId })
                                return {
                                    totalCount: existing.totalCount - 1,
                                    edges: existing.edges.filter(
                                        (edge: { node: Reference }): boolean => edge.node.__ref !== id,
                                    ),
                                }
                                // Apollo Client (<= 3.x) has strict Modifier type requirements that
                                // can be difficult to satisfy when working with complex nested types.
                                // This type assertion works around those type mismatches.
                                // TODO: Consider refactoring to use a helper function for better type safety.
                                // https://discogsinc.atlassian.net/browse/RALM-2748
                            }) as unknown as Modifier<null>,
                        },
                    })
                }
            },
        }).catch(onError)
    }

    return [removeR, info]
}

type ToggleHelpfulOptions = {
    discogsReviewId: number
}

export function useToggleFoundReviewHelpfulMutation(opts: ToggleHelpfulOptions): Mutation {
    const { discogsReviewId } = opts
    const [perform, info] = useToggleFoundReviewHelpfulMutation_()
    const onError = useErrorHandler(t`Could not mark review as helpful`)

    async function toggle(): Promise<void> {
        await perform({
            variables: {
                input: {
                    discogsReviewId,
                },
            },
            update(
                cache: ApolloCache<ToggleFoundReviewHelpfulMutation>,
                result: FetchResult<ToggleFoundReviewHelpfulMutation>,
            ): void {
                cache.modify({
                    id: cache.identify({ __typename: 'Review', discogsId: discogsReviewId }),
                    fields: {
                        foundHelpful(existing: number | undefined): number | undefined {
                            const ok = result.data?.toggleFoundReviewHelpful.helpful
                            if (typeof ok !== 'boolean') {
                                return existing
                            }

                            const before = existing ?? 0
                            const delta = ok ? 1 : -1
                            return Math.max(0, before + delta)
                        },
                    },
                })
            },
        }).catch(onError)
    }

    return [toggle, info]
}

type CreateReleaseOptions = {
    discogsId: number
    keyReleaseId?: number
    parent?: TopLevelReviewFragment
    content: string
    itemType: ItemType
}

export function useCreateReleaseReviewMutation(opts: CreateReleaseOptions): Mutation {
    const { content, parent, discogsId, itemType, keyReleaseId } = opts

    const releaseId = itemType === ItemType.MasterRelease ? keyReleaseId : discogsId
    const { typeName } = mutations[itemType]

    const [perform, info] = useCreateReleaseReviewMutation_()

    async function createR(): Promise<void> {
        await perform({
            variables: {
                input: {
                    content,
                    discogsReleaseId: releaseId as number,
                    discogsReviewId: parent?.discogsId,
                },
            },
            update(
                cache: ApolloCache<CreateReleaseReviewMutation>,
                result: FetchResult<CreateReleaseReviewMutation>,
            ): void {
                if (parent) {
                    const id = cache.identify({ __typename: 'Review', discogsId: parent.discogsId })
                    cache.modify({
                        id,
                        fields: {
                            replies: ((existing: Reference[] | undefined): Reference[] | undefined => {
                                if (!result.data) {
                                    return existing
                                }

                                const ref = cache.writeFragment({
                                    data: result.data.reviewRelease.review,
                                    fragment: ReleaseReviewsFragmentDoc,
                                    fragmentName: 'ReleaseReviews',
                                })

                                if (!ref) {
                                    return existing
                                }

                                if (!existing) {
                                    return [ref]
                                }

                                return [ref, ...existing]
                                // Apollo Client (<= 3.x) has strict Modifier type requirements that
                                // can be difficult to satisfy when working with complex nested types.
                                // This type assertion works around those type mismatches.
                                // TODO: Consider refactoring to use a helper function for better type safety.
                                // https://discogsinc.atlassian.net/browse/RALM-2748
                            }) as unknown as Modifier<null>,
                        },
                    })
                } else {
                    cache.modify({
                        id: cache.identify({ __typename: typeName, discogsId }),
                        fields: {
                            reviews: ((existing: ReviewConnection | undefined): ReviewConnection | undefined => {
                                if (!result.data || !result.data.reviewRelease.review || !existing) {
                                    return existing
                                }

                                const ref = cache.writeFragment({
                                    data: result.data.reviewRelease.review,
                                    fragment: ReleaseReviewsFragmentDoc,
                                    fragmentName: 'ReleaseReviews',
                                })

                                if (!ref) {
                                    return existing
                                }

                                const edge = {
                                    node: ref,
                                    // This is a bit of a hack.
                                    // Technically we don't know what the cursor for the new review will be,
                                    // but we can create it ourselves by mimicking the server side implementation.
                                    // This, however, is fragile and adds coupling.
                                    cursor: btoa(
                                        `ReleaseReviewPagination:${result.data.reviewRelease.review.discogsId}`,
                                    ),
                                }

                                return {
                                    totalCount: existing.totalCount + 1,
                                    edges: [edge, ...existing.edges],
                                }
                                // Apollo Client (<= 3.x) has strict Modifier type requirements that
                                // can be difficult to satisfy when working with complex nested types.
                                // This type assertion works around those type mismatches.
                                // TODO: Consider refactoring to use a helper function for better type safety.
                                // https://discogsinc.atlassian.net/browse/RALM-2748
                            }) as unknown as Modifier<null>,
                        },
                    })
                }
            },
        }).catch((): void => undefined)
    }

    return [createR, info]
}

type EditOptions = {
    reviewId?: number
    content: string
}

export function useEditReviewMutation(opts: EditOptions): Mutation {
    const { reviewId, content } = opts
    const [perform, info] = useEditReviewMutation_()

    async function editR(): Promise<void> {
        if (!reviewId) {
            throw new Error('Missing review id')
        }
        await perform({
            variables: {
                input: {
                    content,
                    discogsReviewId: reviewId,
                },
            },
        }).catch((): void => undefined)
    }

    return [editR, info]
}

type CreateOptions = {
    discogsId: number
    content: string
    parent?: TopLevelReviewFragment
}

export function useCreateArtistReviewMutation(opts: CreateOptions): Mutation {
    const { content, parent, discogsId } = opts
    const [perform, info] = useCreateArtistReviewMutation_()

    async function createR(): Promise<void> {
        await perform({
            variables: {
                input: {
                    content,
                    discogsArtistId: discogsId,
                    discogsReviewId: parent?.discogsId,
                },
            },
            update(
                cache: ApolloCache<CreateArtistReviewMutation>,
                result: FetchResult<CreateArtistReviewMutation>,
            ): void {
                if (parent) {
                    const id = cache.identify({ __typename: 'Review', discogsId: parent.discogsId })
                    cache.modify({
                        id,
                        fields: {
                            replies: ((existing: Reference[] | undefined): Reference[] | undefined => {
                                if (!result.data) {
                                    return existing
                                }

                                const ref = cache.writeFragment({
                                    data: result.data.reviewArtist.review,
                                    fragment: ArtistReviewsFragmentDoc,
                                    fragmentName: 'ArtistReviews',
                                })

                                if (!ref) {
                                    return existing
                                }

                                if (!existing) {
                                    return [ref]
                                }

                                return [ref, ...existing]
                                // Apollo Client (<= 3.x) has strict Modifier type requirements that
                                // can be difficult to satisfy when working with complex nested types.
                                // This type assertion works around those type mismatches.
                                // TODO: Consider refactoring to use a helper function for better type safety.
                                // https://discogsinc.atlassian.net/browse/RALM-2748
                            }) as unknown as Modifier<null>,
                        },
                    })
                } else {
                    cache.modify({
                        id: cache.identify({ __typename: 'Artist', discogsId }),
                        fields: {
                            reviews: ((existing: ReviewConnection | undefined): ReviewConnection | undefined => {
                                if (!result.data || !result.data.reviewArtist.review || !existing) {
                                    return existing
                                }

                                const ref = cache.writeFragment({
                                    data: result.data.reviewArtist.review,
                                    fragment: ArtistReviewsFragmentDoc,
                                    fragmentName: 'ArtistReviews',
                                })

                                if (!ref) {
                                    return existing
                                }

                                const edge = {
                                    node: ref,
                                    // This is a bit of a hack.
                                    // Technically we don't know what the cursor for the new review will be,
                                    // but we can create it ourselves by mimicking the server side implementation.
                                    // This, however, is fragile and adds coupling.
                                    cursor: btoa(`ArtistReviewPagination:${result.data.reviewArtist.review.discogsId}`),
                                }

                                return {
                                    totalCount: existing.totalCount + 1,
                                    edges: [edge, ...existing.edges],
                                }
                                // Apollo Client (<= 3.x) has strict Modifier type requirements that
                                // can be difficult to satisfy when working with complex nested types.
                                // This type assertion works around those type mismatches.
                                // TODO: Consider refactoring to use a helper function for better type safety.
                                // https://discogsinc.atlassian.net/browse/RALM-2748
                            }) as unknown as Modifier<null>,
                        },
                    })
                }
            },
        }).catch((): void => undefined)
    }

    return [createR, info]
}

export function useCreateLabelReviewMutation(opts: CreateOptions): Mutation {
    const { content, parent, discogsId } = opts
    const [perform, info] = useCreateLabelReviewMutation_()

    async function createR(): Promise<void> {
        await perform({
            variables: {
                input: {
                    content,
                    discogsLabelId: discogsId,
                    discogsReviewId: parent?.discogsId,
                },
            },
            update(
                cache: ApolloCache<CreateLabelReviewMutation>,
                result: FetchResult<CreateLabelReviewMutation>,
            ): void {
                if (parent) {
                    const id = cache.identify({ __typename: 'Review', discogsId: parent.discogsId })
                    cache.modify({
                        id,
                        fields: {
                            replies: ((existing: Reference[] | undefined): Reference[] | undefined => {
                                if (!result.data) {
                                    return existing
                                }

                                const ref = cache.writeFragment({
                                    data: result.data.reviewLabel.review,
                                    fragment: LabelReviewsFragmentDoc,
                                    fragmentName: 'LabelReviews',
                                })

                                if (!ref) {
                                    return existing
                                }

                                if (!existing) {
                                    return [ref]
                                }

                                return [ref, ...existing]
                                // Apollo Client (<= 3.x) has strict Modifier type requirements that
                                // can be difficult to satisfy when working with complex nested types.
                                // This type assertion works around those type mismatches.
                                // TODO: Consider refactoring to use a helper function for better type safety.
                                // https://discogsinc.atlassian.net/browse/RALM-2748
                            }) as unknown as Modifier<null>,
                        },
                    })
                } else {
                    cache.modify({
                        id: cache.identify({ __typename: 'Label', discogsId }),
                        fields: {
                            reviews: ((existing: ReviewConnection | undefined): ReviewConnection | undefined => {
                                if (!result.data || !result.data.reviewLabel.review || !existing) {
                                    return existing
                                }

                                const ref = cache.writeFragment({
                                    data: result.data.reviewLabel.review,
                                    fragment: LabelReviewsFragmentDoc,
                                    fragmentName: 'LabelReviews',
                                })

                                if (!ref) {
                                    return existing
                                }

                                const edge = {
                                    node: ref,
                                    // This is a bit of a hack.
                                    // Technically we don't know what the cursor for the new review will be,
                                    // but we can create it ourselves by mimicking the server side implementation.
                                    // This, however, is fragile and adds coupling.
                                    cursor: btoa(`LabelReviewPagination:${result.data.reviewLabel.review.discogsId}`),
                                }

                                return {
                                    totalCount: existing.totalCount + 1,
                                    edges: [edge, ...existing.edges],
                                }
                                // Apollo Client (<= 3.x) has strict Modifier type requirements that
                                // can be difficult to satisfy when working with complex nested types.
                                // This type assertion works around those type mismatches.
                                // TODO: Consider refactoring to use a helper function for better type safety.
                                // https://discogsinc.atlassian.net/browse/RALM-2748
                            }) as unknown as Modifier<null>,
                        },
                    })
                }
            },
        }).catch((): void => undefined)
    }

    return [createR, info]
}
