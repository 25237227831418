import * as React from 'react'
import { Trans } from '@lingui/macro'
import { Button } from '@discogs/amped/button'
import { ShareSquareO, Twitter, Facebook } from '@discogs/amped/icons'

import { Tooltip, Container } from '../../lib/components/tooltip'
import { addState, WithProps } from '../../lib/add-state'

import css from './styles.css'
import { down, esc, up } from '../../lib/keycodes'

type Props = {
    title: string
    url: string
}

type State = {
    open: boolean
}

type Action = { type: 'toggle' } | { type: 'close' }

export function reducer(props: Props, state: State, action: Action): State {
    switch (action.type) {
        case 'toggle':
            return { ...state, open: !state.open }
        case 'close':
            return { ...state, open: false }
        default:
            return state
    }
}

type UIProps = WithProps<Props, State, Action>

export function ShareButtonUI(props: UIProps): React.ReactElement {
    const { dispatch, open, url, title } = props

    const fbookButton = React.useRef<HTMLAnchorElement>(null)
    const twitterButton = React.useRef<HTMLAnchorElement>(null)

    function keyHandler(e: { key: string }) {
        if (e.key === up) {
            fbookButton.current?.focus()
        }
        if (e.key === down) {
            twitterButton.current?.focus()
        }
        if (e.key === esc) {
            dispatch({ type: 'close' })
        }
    }

    const facebook = `http://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`
    const twitter = `http://twitter.com/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(
        title,
    )}&via=discogs`

    return (
        <div className={css.wrapper}>
            <Container>
                <Button
                    onClick={(): void => dispatch({ type: 'toggle' })}
                    aria-haspopup='menu'
                    aria-expanded={open}
                    variant='ghost'
                    dense
                >
                    <ShareSquareO className={css.icon} aria-hidden='true' />
                    <Trans>Share</Trans>
                </Button>
                <Tooltip
                    title={<Trans>Share</Trans>}
                    position='bottomEnd'
                    open={open}
                    onClose={(): void => dispatch({ type: 'close' })}
                    id='release-share-tooltip'
                    role='menu'
                    ariaLabel='Share'
                    tabIndex={-1}
                    ariaHidden
                    keyHandler={keyHandler}
                >
                    <div className={css.content}>
                        <a
                            href={facebook}
                            target='_blank'
                            rel='noopener noreferrer'
                            className={css.facebook}
                            role='menuitem'
                            ref={fbookButton}
                        >
                            <Facebook />
                            <Trans>Facebook</Trans>
                        </a>
                        <br />
                        <a
                            href={twitter}
                            target='_blank'
                            rel='noopener noreferrer'
                            className={css.twitter}
                            role='menuitem'
                            ref={twitterButton}
                        >
                            <Twitter />
                            <Trans>Twitter</Trans>
                        </a>
                    </div>
                </Tooltip>
            </Container>
        </div>
    )
}

const initial = {
    open: false,
}

export const ShareButton = addState(ShareButtonUI, reducer, initial)
