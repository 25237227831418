import * as React from 'react'

import css from './styles.css'
import { Typography } from '@mui/material'

export type Props = {
    title: React.ReactNode
    children: React.ReactNode
    images?: React.ReactNode
}

export function Header(props: Props): React.ReactElement {
    const { title, images, children } = props

    return (
        <div className={css.body}>
            <Typography
                component='h1'
                variant='headLineXL'
                sx={{ marginLeft: '1.5rem', marginBottom: '.25rem' }}
                className={css.title}
            >
                {title}
            </Typography>
            <div className={css.thumbnail}>{images}</div>
            <div className={css.info}>{children}</div>
        </div>
    )
}
