import * as React from 'react'
import classnames from 'classnames'
import { Trans, t } from '@lingui/macro'

import { Modal } from '../../lib/components/modal'
import { TextArea } from '../../lib/components/textarea'
import { Input } from '../../lib/components/input'
import { Label } from '../../lib/components/label'
import { useLocalisation } from '../../lib/i18n'
import { useLogin } from '../../lib/login'
import { Button } from '../../lib/components/buttons'
import { Spinner } from '../../lib/components/spinner'
import { useBlockScroll } from '../../lib/use-block-scroll'
import { useKeyPress } from '../../lib/use-key-press'
import { esc } from '../../lib/keycodes'
import { ItemType, useUserAllListsWithItemLazyQuery, UserReleaseListsFragment } from '../../api/types'

import { useAddItemToExistingListMutation } from '../../mutations/add-item-to-existing-list'
import { useAddItemToNewListMutation } from '../../mutations/add-item-to-new-list'

import css from './styles.css'

type ListEdge = UserReleaseListsFragment['lists']['edges'][number]

export type Item = {
    type: ItemType
    discogsId: number
}

export type ListModal = {
    open: (type: ItemType, discogsId: number) => void
    close: () => void
    isOpen: boolean
    component: React.ReactNode
}

type FormState = {
    existing: boolean
    comment: string
    title: string
    description: string
    list: number | null
}

export function useAddItemToListModal(): ListModal {
    const { i18n } = useLocalisation()
    const ref = React.useRef<HTMLFormElement>(null)
    const [current, setCurrent] = React.useState<Item | null>(null)
    const [userLists, data] = useUserAllListsWithItemLazyQuery()
    const { loggedIn } = useLogin()
    const [form, setForm] = React.useState<FormState>({
        existing: true,
        comment: '',
        title: '',
        description: '',
        list: null,
    })
    useBlockScroll({ block: Boolean(current) })
    useKeyPress({ [esc]: close })

    const addToExistingList = useAddItemToExistingListMutation()
    const addToNewList = useAddItemToNewListMutation()

    const edges = Array.from(data.data?.viewer?.lists.edges ?? [])
    const forceNew = edges.length === 0
    const valid = form.existing && !forceNew ? form.list !== null : form.title.length > 0
    const saving = addToExistingList.loading || addToNewList.loading

    React.useEffect(
        function () {
            setForm({
                existing: true,
                comment: '',
                title: '',
                description: '',
                list: null,
            })

            // TODO: focus on form
            ref.current?.focus()
        },
        [current],
    )

    function open(type: ItemType, discogsId: number): void {
        if (!loggedIn) {
            return
        }

        setCurrent({ type, discogsId })
        void userLists({ variables: { itemType: type, discogsId } }).catch((e) => {
            throw new Error('add-item-to-list userLists error')
        })
    }

    function close(): void {
        setCurrent(null)
    }

    function setExisting() {
        setForm((form) => ({ ...form, existing: true }))
    }

    function setNew() {
        setForm((form) => ({ ...form, existing: false }))
    }

    function handleCommentChange(evt: React.ChangeEvent<HTMLTextAreaElement>) {
        setForm((form) => ({ ...form, comment: evt.target.value }))
    }

    function handleTitleChange(evt: React.ChangeEvent<HTMLInputElement>) {
        setForm((form) => ({ ...form, title: evt.target.value }))
    }

    function handleDescriptionChange(evt: React.ChangeEvent<HTMLTextAreaElement>) {
        setForm((form) => ({ ...form, description: evt.target.value }))
    }

    function handleListChange(evt: React.ChangeEvent<HTMLSelectElement>): void {
        const discogsId = parseInt(evt.target.value, 10)
        setForm((form) => ({ ...form, list: discogsId === -1 ? null : discogsId }))
    }

    async function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
        evt.preventDefault()

        if (!current || !valid || saving) {
            return
        }

        if (form.existing) {
            await addToExistingList.perform({
                itemType: current.type,
                discogsId: current.discogsId,
                listId: form.list,
                comment: form.comment,
            })

            close()
        }

        if (!form.existing) {
            await addToNewList.perform({
                itemType: current.type,
                discogsId: current.discogsId,
                title: form.title,
                description: form.description,
                comment: form.comment,
            })

            close()
        }
    }

    const ids = new Set(data.data?.viewer?.withItem.edges.map((edge) => edge.node?.discogsId) ?? [])

    let title = <Trans>Add Master to List</Trans>
    if (current?.type === ItemType.Release) {
        title = <Trans>Add Release to List</Trans>
    } else if (current?.type === ItemType.Artist) {
        title = <Trans>Add Artist to List</Trans>
    }

    const component = (
        <Modal title={title} open={Boolean(current)} onClose={close}>
            <form
                onSubmit={(evt) => void handleSubmit(evt)}
                className={classnames(css.form, data.loading && css.loading)}
                ref={ref}
            >
                <div>
                    <input
                        type='radio'
                        id='existing'
                        name='new-or-existing'
                        onChange={setExisting}
                        checked={form.existing}
                        disabled={forceNew || data.loading}
                    />
                    <label htmlFor='existing' className={classnames(forceNew && css.disabled)}>
                        <Trans>Existing List</Trans>
                    </label>
                    <input
                        type='radio'
                        id='new'
                        name='new-or-existing'
                        onChange={setNew}
                        checked={!form.existing}
                        disabled={data.loading}
                    />
                    <label htmlFor='new'>
                        <Trans>New List</Trans>
                    </label>
                </div>

                {form.existing && (
                    <div key='existing'>
                        <Label htmlFor='list'>
                            <Trans>List</Trans>
                        </Label>
                        {}
                        <select id='list' onChange={handleListChange} disabled={data.loading}>
                            <option value='-1' />
                            <optgroup label={t(i18n)`Recently Used`}>
                                {edges
                                    .sort(function (a: ListEdge, b: ListEdge): number {
                                        if (!a.node || !b.node) {
                                            return 0
                                        }
                                        return a.node.modified < b.node.modified ? 1 : -1
                                    })
                                    .slice(0, 5)
                                    .map(function (edge: ListEdge): React.ReactElement | null {
                                        if (!edge.node) {
                                            return null
                                        }

                                        const isInList = ids.has(edge.node.discogsId)

                                        return (
                                            <option
                                                value={edge.node.discogsId}
                                                key={edge.node.discogsId}
                                                disabled={isInList}
                                            >
                                                {edge.node.name}
                                            </option>
                                        )
                                    })}
                            </optgroup>
                            <optgroup label={t(i18n)`All Lists`}>
                                {edges
                                    .sort(function (a: ListEdge, b: ListEdge): number {
                                        if (!a.node || !b.node) {
                                            return 0
                                        }
                                        return a.node.name < b.node.name ? -1 : 1
                                    })
                                    .map(function (edge: ListEdge): React.ReactElement | null {
                                        if (!edge.node) {
                                            return null
                                        }

                                        const isInList = ids.has(edge.node.discogsId)

                                        return (
                                            <option
                                                value={edge.node.discogsId}
                                                key={edge.node.discogsId}
                                                disabled={isInList}
                                            >
                                                {edge.node.name}
                                            </option>
                                        )
                                    })}
                            </optgroup>
                        </select>
                    </div>
                )}
                {!form.existing && (
                    <>
                        <div key='new'>
                            <Label htmlFor='title'>
                                <Trans>Title</Trans>
                            </Label>
                            <br />
                            <Input
                                id='title'
                                type='text'
                                value={form.title}
                                onChange={handleTitleChange}
                                disabled={data.loading}
                                aria-required='true'
                            />
                        </div>
                        <div>
                            <Label htmlFor='description' optional>
                                <Trans>Description</Trans>
                            </Label>
                            <TextArea
                                id='description'
                                onChange={handleDescriptionChange}
                                value={form.description}
                                disabled={data.loading}
                                aria-required='false'
                            />
                        </div>
                    </>
                )}
                <div>
                    <Label htmlFor='comment' optional>
                        <Trans>Comments on this item</Trans>
                    </Label>
                    <TextArea
                        id='comment'
                        onChange={handleCommentChange}
                        value={form.comment}
                        disabled={data.loading}
                        aria-required='false'
                    />
                </div>
                <Button type='submit' color='green' disabled={saving || !valid || data.loading}>
                    {saving && (
                        <>
                            <Spinner /> <Trans>Saving...</Trans>
                        </>
                    )}
                    {!saving && <Trans>Save</Trans>}
                </Button>
                <Button disabled={saving} onClick={close}>
                    <Trans>Cancel</Trans>
                </Button>
            </form>
        </Modal>
    )

    return {
        open,
        close,
        isOpen: Boolean(current),
        component,
    }
}
