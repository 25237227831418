import * as React from 'react'

import css from './styles.css'
import { Typography } from '@mui/material'

type Props = {
    children: React.ReactNode
}

export function HeaderTable(props: Props): React.ReactElement {
    const { children } = props

    return (
        <table className={css.table}>
            <tbody>{children}</tbody>
        </table>
    )
}

type ItemProps = {
    name: React.ReactElement | string
    children: React.ReactNode
}

export function Item(props: ItemProps): React.ReactElement {
    const { name, children } = props
    return (
        <tr>
            <th scope='row'>
                <Typography component='h2' variant='labelSmall'>
                    {name}:
                </Typography>
            </th>
            <td>{children}</td>
        </tr>
    )
}
