import * as React from 'react'
import classnames from 'classnames'

import { Spinner as Icon } from '../icon'
import css from './styles.css'

type Props = {
    className?: string
    large?: boolean
    delay?: number
}

export function Spinner(props: Props): React.ReactElement {
    const { className, large, delay = 0 } = props
    const [show, setShow] = React.useState(delay <= 0)

    React.useEffect(function (): () => void {
        const t = setTimeout((): void => setShow(true), delay)
        return (): void => clearTimeout(t)
    }, [])

    const classname = classnames(css.spinner, className, large && css.large, show && css.show)
    return <Icon aria-label='loading' className={classname} />
}
